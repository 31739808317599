import React, { useEffect } from "react";

import Link from "../hooks/Link";
import withLayout from "../hoc/withLayout/withLayout";
import * as Analytics from "../utils/analytics";
import { SEO } from "../components";

const Error404 = () => {
  useEffect(() => {
    Analytics.track("404 Page Load");
  }, []);

  return (
    <>
      <SEO title="Oops! This page has gone on vacation" />
      <div className="component-default-padding min-h-[50vh]">
        <h1 className="mb-8 text-center text-2xl md:mb-14 md:text-4xl lg:text-5xl">
          Oops! This page has gone on vacation.
        </h1>
        <div className="flex justify-center gap-5 text-xl underline md:gap-12 md:text-4xl">
          <Link to="/">Home</Link>
          <Link to="/support">Help</Link>
        </div>
      </div>
    </>
  );
};

export default withLayout(Error404);
